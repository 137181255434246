import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import { Input, Button, Modal } from "antd"
import QRCode from "qrcode.react"
import ContractLayout from "../../components/ContractLayout"
import SEO from "../../components/SEO"
import style from "./index.module.scss"
import contractLoadingImg from "../../images/icon/contract-loading.png"
import copyImg from "../../images/icon/copy.png"
import { URL_SCAN } from "../../utils/const"
// import { sendTransaction } from "../../utils/conflux"
const IndexPage = () => {
  const intl = useIntl()
  let countDown = 5
  const preContractLoadingText = "合同信息上链中"
  const [contractLoading, setContractLoading] = useState(false)
  const [showContractSuccess, setShowContractSuccess] = useState(false)
  const [contractLoadingText, setContractLoadingText] = useState(
    `${preContractLoadingText}(${countDown}S)......`
  )
  const [tx] = useState(
    "0x0fda6743387ee3954834fa27031c3151cb314df1272a3733752554a4151cd123"
  )
  const modalBodyStyle = {
    textAlign: "center",
    paddingBottom: "32px",
  }
  function signContract() {
    setContractLoading(true)
    // sendTransaction().then(data => {
    //   setTx(data.hash)
    //   console.log(data)
    // })
    const interval = setInterval(() => {
      if (countDown === 0) {
        setContractLoading(false)
        clearInterval(interval)
        setShowContractSuccess(true)
      }
      setContractLoadingText(`${preContractLoadingText}(${countDown}S)......`)
      countDown--
    }, 1000)
  }
  function handleLoadingCancel(e) {
    e.preventDefault()
    setContractLoading(false)
  }

  function handleSuccessCancel(e) {
    e.preventDefault()
    setShowContractSuccess(false)
  }
  return (
    <ContractLayout>
      <SEO
        title={intl.formatMessage({
          id: "seo.home.title",
        })}
        lang={intl.formatMessage({
          id: "seo.home.lang",
        })}
        description={intl.formatMessage({
          id: "seo.home.description",
        })}
        keywords={intl.formatMessage({
          id: "seo.home.keywords",
        })}
      />
      <div className={style.container}>
        <div className={style.contractContainer}>
          <div className={style.leftContainer} />
          <div className={style.righContainer}>
            <div
              className={classNames(
                style.section,
                style.one,
                style.btnContainer
              )}
            >
              <Button
                type="primary"
                disabled
                className={classNames(style.btn, style.see)}
              >
                保存至草稿箱
              </Button>
              <Button
                type="primary"
                className={classNames(
                  style.btn,
                  style.primary,
                  style.margin_small
                )}
                onClick={signContract}
              >
                生成区块链合同
              </Button>
            </div>
            <div className={classNames(style.section, style.two)} />
            <div className={classNames(style.section, style.three)} />
            <div className={classNames(style.section, style.four)} />
            <div className={classNames(style.section, style.inputContainer)}>
              <Input bordered={false} className={style.customInput} />
              <Input bordered={false} className={style.customInput} />
            </div>
            <div className={classNames(style.section, style.five)} />
          </div>
        </div>
        <Modal
          visible={contractLoading}
          bodyStyle={modalBodyStyle}
          footer={null}
          onCancel={handleLoadingCancel}
          centered
        >
          <img src={contractLoadingImg} className={style.contractLoadingImg} />
          <div>
            <span className={style.contractTip}>{contractLoadingText}</span>
          </div>
        </Modal>
        <Modal
          visible={showContractSuccess}
          bodyStyle={modalBodyStyle}
          footer={null}
          onCancel={handleSuccessCancel}
          centered
        >
          <QRCode
            value={URL_SCAN + `/transactionsdetail/${tx}`}
            className={style.qcContainer}
          />
          <div>
            <span className={style.contractSuccessTip}>合同信息已成功上链</span>
          </div>
          <div className={style.hashContainer}>
            哈希:<span className={style.hash}>{tx}</span>
            <img src={copyImg} className={style.copyImgSmall} />
          </div>
          <Button
            type="primary"
            className={classNames(
              style.btn,
              style.primary,
              style.link,
              style.margin_top_link
            )}
            href={URL_SCAN + `/transactionsdetail/${tx}`}
            target="_black"
          >
            查看链接
          </Button>
        </Modal>
      </div>
    </ContractLayout>
  )
}

export default IndexPage
